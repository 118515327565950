//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlider from 'vue-slider-component';
import ViuInput from 'viu/components/viu-input/viu-input.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';

import './style/slider-componente-style.scss';

export default {
  name: 'SliderComponenteBateriaVirtual',
  components: {
    VueSlider,
    ViuInput,
    ViuLabel
  },
  props: {
    id: {
      type: Number
    },
    value: {
      type: [Number, Object]
    },
    allSteps: {
      type: Object
    },
    postInputSlider: {
      type: String,
      default: ''
    },
    postInput: {
      type: String,
      default: ''
    },
    titleInput: {
      type: String,
      default: ''
    },
    titleLabel: {
      type: String,
      default: ''
    },
    postInputLabel: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    disabledSlider: {
      type: Object,
      default: () => ({})
    },
    minProp: {
      type: Number,
      default: 0
    },
    maxProp: {
      type: Number,
      default: 0
    },
    idChanged: {
      type: Array
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    min: 0,
    max: 0,
    slider: null,
    inputModel: null,
    importeFinal: '',
    potencia: '',
    potenciaGrouped: false
  }),
  watch: {
    slider: {
      inmediate: true,
      handler(val) {
        if (this.slider === '' || this.slider === null || this.slider === undefined) {
          this.slider = JSON.parse(JSON.stringify(this.min));
          return;
        }
        this.inputModel = JSON.parse(JSON.stringify(val));
      }
    },
    inputModel: {
      handler(val) {
        if (val === '' || val === undefined) {
          this.slider = Number(JSON.parse(JSON.stringify(this.min)));
          this.inputModel = Number(JSON.parse(JSON.stringify(this.min)));
          this.$refs.inputModalBateria.stored = Number(JSON.parse(JSON.stringify(this.min)));
        }

        if (val <= this.min && val !== null && val !== '') {
          this.inputModel = Number(JSON.parse(JSON.stringify(this.min)));
          this.slider = Number(JSON.parse(JSON.stringify(this.min)));
          this.$refs.inputModalBateria.stored = Number(JSON.parse(JSON.stringify(this.min)));
        }

        if (val >= this.max && val !== null) {
          this.$nextTick(() => {
            this.inputModel = Number(JSON.parse(JSON.stringify(this.max)));
            this.slider = Number(JSON.parse(JSON.stringify(this.max)));
            this.$refs.inputModalBateria.stored = Number(JSON.parse(JSON.stringify(this.max)));
          });
        }

        if (
          this.inputModel < this.max &&
          this.inputModel !== null &&
          this.inputModel > this.min &&
          val !== ''
        ) {
          this.slider = Number(JSON.parse(JSON.stringify(val)));
          this.inputModel = Number(JSON.parse(JSON.stringify(val)));
          this.$refs.inputModalBateria.stored = Number(JSON.parse(JSON.stringify(val)));
        }

        this.$emit('input', this.inputModel);
      }
    }
  },
  created() {
    this.disabledSwitch(this.disabledSlider);
    this.min = this.minProp;
    this.max = this.maxProp;
    this.inputModel = this.min;

    if (this.allSteps['4'].finished && this.idChanged.includes(this.id)) {
      this.setData();
    }
  },
  methods: {
    setData() {
      this.allSteps['4'].inputData.selectedRates.forEach(element => {
        if (element.id === this.id) {
          if (this.titleInput === 'Comisión Gestión') {
            this.slider = element.bateriaVirtual.valueGestionBateria;
            this.inputModel = element.bateriaVirtual.valueGestionBateria;
          }

          if (this.titleInput === 'Reducción de desvíos') {
            this.slider = element.bateriaVirtual.valueReduccionDesviacion;
            this.inputModel = element.bateriaVirtual.valueReduccionDesviacion;
          }
        }
      });
    },
    disabledSwitch(value) {
      if (Object.values(value).length === 0) {
        this.$emit('disabled', true);
        return;
      }

      // sacamos el menor de los maximos
      const maximos = Object.values(value.max).splice(0, this.disabledSlider.totalPeriods.length);
      const maximo = Math.min(...maximos);

      // sacamos el mayor de los minimos
      const minimos = Object.values(value.min).splice(0, this.disabledSlider.totalPeriods.length);
      const minimo = Math.max(...minimos);

      if (maximo !== null && minimo !== null && maximo > minimo) {
        this.$emit('disabled', false);

        return;
      }
      this.$emit('disabled', true);
    },
    checkUndefined(texto) {
      if (texto !== undefined && texto !== null) return texto;
      return '';
    },
    moneyFormat(value) {
      if (this.checkUndefined(value) === '') return '-';
      return new Intl.NumberFormat('de-DE').format(Number(value));
    }
  }
};
