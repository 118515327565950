//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuBox from 'viu/components/viu-box/viu-box.vue';
import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import ViuInput from 'viu/components/viu-input/viu-input.vue';
import ViuButton from 'viu/components/viu-button/viu-button.vue';
import ViuExpandable from 'viu/components/viu-expandable/viu-expandable.vue';

import './style/detalle-tarifa-style.scss';

export default {
  name: 'DetalleTarifa',
  components: {
    ViuBox,
    ViuHeader,
    ViuLabel,
    ViuInput,
    ViuButton,
    ViuExpandable
  },
  props: {
    rate: {
      type: Object
    }
  },
  data() {
    return {
      openDetalleTarifa: true
    };
  }
};
