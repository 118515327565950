//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuBox from 'viu/components/viu-box/viu-box.vue';
import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import ViuSwitch from 'viu/components/viu-switch/viu-switch.vue';
import ViuUpload from 'viu/components/viu-upload/viu-upload.vue';
import ViuButton from 'viu/components/viu-button/viu-button.vue';
import ViuSelect from 'viu/components/viu-select/viu-select.vue';
import validateForm from '$lib/validate-form.js';

export default {
  name: 'AddAutoconsumoSuministro',
  components: { ViuBox, ViuHeader, ViuLabel, ViuSwitch, ViuUpload, ViuButton, ViuSelect },
  props: {
    staticData: {
      type: Object
    },
    allSteps: {
      type: Object
    }
  },
  data() {
    return {
      dataToSend: {
        tipo_autoconsumo: 'individual',
        excedentes: 'con_excedentes',
        tipo_gestion: 'ya_dispone_en_suministro',
        compensacion_por_excedentes: 'con_compensación',
        tipo_autoconsumo_select: '41',
        documentos_autoconsumo: []
      }
    };
  },
  computed: {
    readOnlyAutoconsumo() {
      if (
        this.allSteps[2].externalData.codigoAutoconsumo !== '00' &&
        this.staticData.optionsAutoconsumoTipoCNMC.some(
          item => item.value === this.allSteps[2].externalData.codigoAutoconsumo
        )
      ) {
        return true;
      }
      return false;
    }
  },
  watch: {
    'dataToSend.tipo_autoconsumo': {
      handler() {
        this.changeTipoAutoconsumo();
      }
    },
    'dataToSend.excedentes': {
      handler() {
        this.changeTipoAutoconsumo();
      }
    },
    'dataToSend.compensacion_por_excedentes': {
      handler() {
        this.changeTipoAutoconsumo();
      }
    }
  },
  created() {
    this.dataToSend.tipo_autoconsumo_select = this.checkUndefined(
      this.allSteps[2].externalData.codigoAutoconsumo
    );
  },
  methods: {
    checkUndefined(texto) {
      if (texto !== undefined && texto !== null) return texto;
      return '';
    },
    sendForm() {
      const blockFields = [];

      const result = validateForm.validate(this, blockFields);

      if (result !== 0) return;

      this.$emit('change-datos-autoconsumo', this.dataToSend);
      this.$emit('cerrar-modal');
    },
    changeTipoAutoconsumo() {
      if (this.dataToSend.excedentes === 'sin_excedentes')
        this.dataToSend.compensacion_por_excedentes = 'sin_compensación';

      if (
        this.dataToSend.tipo_autoconsumo === 'individual' &&
        this.dataToSend.excedentes === 'con_excedentes' &&
        this.dataToSend.compensacion_por_excedentes === 'con_compensación'
      )
        this.dataToSend.tipo_autoconsumo_select = '41';

      if (
        this.dataToSend.tipo_autoconsumo === 'individual' &&
        this.dataToSend.excedentes === 'con_excedentes' &&
        this.dataToSend.compensacion_por_excedentes === 'sin_compensación'
      )
        this.dataToSend.tipo_autoconsumo_select = '51';

      if (
        this.dataToSend.tipo_autoconsumo === 'individual' &&
        this.dataToSend.excedentes === 'sin_excedentes' &&
        this.dataToSend.compensacion_por_excedentes === 'sin_compensación'
      )
        this.dataToSend.tipo_autoconsumo_select = '31';
      if (
        this.dataToSend.tipo_autoconsumo === 'colectivo' &&
        this.dataToSend.excedentes === 'con_excedentes' &&
        this.dataToSend.compensacion_por_excedentes === 'con_compensación'
      )
        this.dataToSend.tipo_autoconsumo_select = '42';
      if (
        this.dataToSend.tipo_autoconsumo === 'colectivo' &&
        this.dataToSend.excedentes === 'con_excedentes' &&
        this.dataToSend.compensacion_por_excedentes === 'sin_compensación'
      )
        this.dataToSend.tipo_autoconsumo_select = '52';
      if (
        this.dataToSend.tipo_autoconsumo === 'colectivo' &&
        this.dataToSend.excedentes === 'sin_excedentes' &&
        this.dataToSend.compensacion_por_excedentes === 'sin_compensación'
      )
        this.dataToSend.tipo_autoconsumo_select = '32';
    }
  }
};
