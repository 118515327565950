/* eslint-disable import/prefer-default-export */
export function createRateV3(rate) {
  return {
    id: rate.id,
    name: rate.nombre,
    alias: rate.alias,
    frases: rate.frases,
    energyMarketerName: rate.comercializadoraNombre,
    type: rate.tipoTarifa,
    tarifaAtr: rate.tarifaAtr,
    selected: false,
    expandible: false,
    bateriaVirtual: {
      maxGestionBateria: rate.bateriaVirtual.maxGestionBateria,
      minGestionBateria: rate.bateriaVirtual.minGestionBateria,
      maxReduccionDesviacion: rate.bateriaVirtual.maxReduccionDesviacion,
      minReduccionDesviacion: rate.bateriaVirtual.minReduccionDesviacion,
      valueGestionBateria: null,
      valueReduccionDesviacion: null,
      anexoBateria: rate.bateriaVirtual.anexoBateria,
      modoActivacionBateria: rate.bateriaVirtual.modoActivacionBateria,
      modoBateria: rate.bateriaVirtual.modoBateria,
      permitirBateriaVirtual: rate.bateriaVirtual.permitirBateriaVirtual
    },
    hiredPower: rate.hiredPower,
    precioBoe: rate.precioBoe,
    potencia: rate.potencia,
    originalState: {
      feesEnergy: rate.energia.agruparFee ? 'grouped' : 'individual',
      powerMargin: rate.potencia.agruparFee ? 'grouped' : 'individual',
      selfConsumption: rate.autoconsumo.agruparFee ? 'grouped' : 'individual'
    },
    states: {
      feesEnergy: rate.energia.agruparFee ? 'grouped' : 'individual',
      powerMargin: rate.potencia.agruparFee ? 'grouped' : 'individual',
      selfConsumption: rate.autoconsumo.agruparFee ? 'grouped' : 'individual'
    },
    values: {
      feesEnergy: {
        totalPeriods:
          rate.tarifaAtr === '018' ? ['p1', 'p2', 'p3'] : ['p1', 'p2', 'p3', 'p4', 'p5', 'p6'],
        min: {
          p1: rate.energia.fee.minP1,
          p2: rate.energia.fee.minP2,
          p3: rate.energia.fee.minP3,
          p4: rate.energia.fee.minP4,
          p5: rate.energia.fee.minP5,
          p6: rate.energia.fee.minP6
        },
        max: {
          p1: rate.energia.fee.maxP1,
          p2: rate.energia.fee.maxP2,
          p3: rate.energia.fee.maxP3,
          p4: rate.energia.fee.maxP4,
          p5: rate.energia.fee.maxP5,
          p6: rate.energia.fee.maxP6
        },
        selected: {
          p1: null,
          p2: null,
          p3: null,
          p4: null,
          p5: null,
          p6: null
        },
        price: {
          p1: rate.energia.precios.p1,
          p2: rate.energia.precios.p2,
          p3: rate.energia.precios.p3,
          p4: rate.energia.precios.p4,
          p5: rate.energia.precios.p5,
          p6: rate.energia.precios.p6
        }
      },
      powerMargin: {
        totalPeriods:
          rate.tarifaAtr === '018' ? ['p1', 'p2'] : ['p1', 'p2', 'p3', 'p4', 'p5', 'p6'],
        min: {
          p1: rate.potencia.valores?.minP1 ?? rate.potencia.fee.minP1,
          p2: rate.potencia.valores?.minP2 ?? rate.potencia.fee.minP2,
          p3: rate.potencia.valores?.minP3 ?? rate.potencia.fee.minP3,
          p4: rate.potencia.valores?.minP4 ?? rate.potencia.fee.minP4,
          p5: rate.potencia.valores?.minP5 ?? rate.potencia.fee.minP5,
          p6: rate.potencia.valores?.minP6 ?? rate.potencia.fee.minP6
        },
        max: {
          p1: rate.potencia.valores?.maxP1 ?? rate.potencia.fee.maxP1,
          p2: rate.potencia.valores?.maxP2 ?? rate.potencia.fee.maxP2,
          p3: rate.potencia.valores?.maxP3 ?? rate.potencia.fee.maxP3,
          p4: rate.potencia.valores?.maxP4 ?? rate.potencia.fee.maxP4,
          p5: rate.potencia.valores?.maxP5 ?? rate.potencia.fee.maxP5,
          p6: rate.potencia.valores?.maxP6 ?? rate.potencia.fee.maxP6
        },
        selected: {
          p1: null,
          p2: null,
          p3: null,
          p4: null,
          p5: null,
          p6: null
        },
        price: {
          p1: rate.potencia.precios.p1,
          p2: rate.potencia.precios.p2,
          p3: rate.potencia.precios.p3,
          p4: rate.potencia.precios.p4,
          p5: rate.potencia.precios.p5,
          p6: rate.potencia.precios.p6
        }
      },
      selfConsumption: {
        totalPeriods:
          rate.tarifaAtr === '018' ? ['p1', 'p2', 'p3'] : ['p1', 'p2', 'p3', 'p4', 'p5', 'p6'],
        min: {
          p1: rate.autoconsumo.fee.minP1,
          p2: rate.autoconsumo.fee.minP2,
          p3: rate.autoconsumo.fee.minP3,
          p4: rate.autoconsumo.fee.minP4,
          p5: rate.autoconsumo.fee.minP5,
          p6: rate.autoconsumo.fee.minP6
        },
        max: {
          p1: rate.autoconsumo.fee.maxP1,
          p2: rate.autoconsumo.fee.maxP2,
          p3: rate.autoconsumo.fee.maxP3,
          p4: rate.autoconsumo.fee.maxP4,
          p5: rate.autoconsumo.fee.maxP5,
          p6: rate.autoconsumo.fee.maxP6
        },
        selected: {
          p1: null,
          p2: null,
          p3: null,
          p4: null,
          p5: null,
          p6: null
        },
        price: {
          p1: rate.autoconsumo.precios.p1,
          p2: rate.autoconsumo.precios.p2,
          p3: rate.autoconsumo.precios.p3,
          p4: rate.autoconsumo.precios.p4,
          p5: rate.autoconsumo.precios.p5,
          p6: rate.autoconsumo.precios.p6
        }
      }
    }
  };
}
