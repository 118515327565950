//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuBox from 'viu/components/viu-box/viu-box.vue';
import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuButton from 'viu/components/viu-button/viu-button.vue';

import * as api from '$network/api.js';

export default {
  name: 'DocumentosAutoconsumo',
  components: { ViuBox, ViuHeader, ViuButton },
  props: {
    documentos: Array,
    documentosOld: Array,
    leadId: String
  },
  data() {
    return {
      document: []
    };
  },
  created() {
    if (this.documentosOld.length > 0) {
      this.document = this.documentosOld.map(documento => {
        return {
          name: documento.nombre,
          documento: documento.filename,
          uid: documento.filename
        };
      });
      return;
    }

    this.document = this.documentos.map(documento => {
      if (documento.name !== undefined) {
        return documento;
      }

      return {
        name: documento.nombre,
        documento: documento.filename,
        uid: documento.filename
      };
    });
  },
  methods: {
    download(documento) {
      if (documento.uid === undefined) {
        const url = window.URL.createObjectURL(new Blob([documento]));
        const link = document.createElement('a');
        link.href = url;
        const { name } = documento;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      if (documento.uid !== undefined) {
        api
          .downloadDocuments(this.leadId, documento.uid)
          .then(response => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute(
              'download',
              response.headers['content-disposition'].split('filename=')[1]
            );
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          })
          .catch(() => {
            this.$notify.error('No se ha podido descargar el informe');
          });
      }
    }
  }
};
