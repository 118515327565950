//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { v4 as uuidv4 } from 'uuid';
import ViuBox from 'viu/components/viu-box/viu-box.vue';
import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import ViuInput from 'viu/components/viu-input/viu-input.vue';
import ViuSwitch from 'viu/components/viu-switch/viu-switch.vue';
import ViuSelect from 'viu/components/viu-select/viu-select.vue';
import ViuCalendar from 'viu/components/viu-calendar/viu-calendar.vue';
import ViuButton from 'viu/components/viu-button/viu-button.vue';
import validateForm from '$lib/validate-form.js';
import * as api from '$network/api.js';

import './style/add-bateria-virtual-style.scss';

export default {
  name: 'AddBateriaVirtual',
  components: {
    ViuBox,
    ViuHeader,
    ViuLabel,
    ViuSwitch,
    ViuInput,
    ViuSelect,
    ViuCalendar,
    ViuButton
  },
  props: {
    staticData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dataToSend: {
        tipo_gestion_bateria_virtual: '1',
        modo_bateria: 'U',
        alfa: null,
        modo_activacion: '',
        fecha: '',
        codigo_bateria_virtual: '',
        identificador_asociado: ''
      },
      validado: false,
      clasificacion: '1'
    };
  },
  watch: {
    'dataToSend.tipo_gestion_bateria_virtual': {
      handler() {
        this.dataToSend.modo_bateria = 'U';
        this.dataToSend.alfa = null;
        this.dataToSend.modo_activacion = '';
        this.dataToSend.fecha = '';
        this.dataToSend.codigo_bateria_virtual = '';
        this.dataToSend.identificador_asociado = '';
      }
    }
  },
  methods: {
    validarBateriaVirtual() {
      if (validateForm.validate(this, []) > 0) return;
      api
        .existBateriaVirtual(
          this.dataToSend.codigo_bateria_virtual,
          this.dataToSend.identificador_asociado
        )
        .then(response => {
          if (response.status === 200) {
            if (response.data.virtual_battery.existe) {
              this.validado = true;
              this.clasificacion = response.data.virtual_battery.tipoBateria;
            } else {
              this.$notify.error('Batería virtual inexistente');
            }
          }
        })
        .catch(() => {
          this.$notify.error('Ha ocurrido un error al comprobar la batería virtual');
        });
    },
    deleteBateria() {
      this.dataToSend.codigo_bateria_virtual = '';
      this.dataToSend.identificador_asociado = '';
      this.dataToSend.modo_activacion = '';
      this.dataToSend.fecha = '';
      this.validado = false;
    },
    sendForm() {
      if (this.dataToSend.tipo_gestion_bateria_virtual === '2' && !this.validado) return;
      if (validateForm.validate(this, []) > 0) return;
      if (this.dataToSend.tipo_gestion_bateria_virtual === '1') {
        this.dataToSend.codigo_bateria_virtual = uuidv4();
      }
      this.$emit('change', this.dataToSend);
      this.$emit('cerrar-modal');
    }
  }
};
